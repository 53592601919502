// @flow
import * as React from 'react';
import { NIBBLER_LIST_QUERY, } from '@haaretz/graphql';
import NibblerView from './NibblerView.js';
import ListWrapper from '../../ListWrapper';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';


type Props = {
  updateListDuplication: Function,
  variables: {
    history: ?(string[]),
    getHistory: ?(() => string[]),
    offset: number,
  },
  listData: ListDataType,
};

export default function Nibbler(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={NIBBLER_LIST_QUERY} view="Nibbler">
      {dataProps => <NibblerView {...dataProps} />}
    </ListWrapper>
  );
}
