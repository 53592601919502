// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';
import List from '../List/List';
import ListView from '../ListView/NewListView';

import AdSlot from '../AdManager/AdSlot';
import useGetComponent from '../../hooks/GetComponentContext/useGetComponent';
import { isIframe, } from '../../utils/validateType';

import type { ListDataType, } from '../../flowTypes/ListDataType';
import type { AdSlotType, } from '../../flowTypes/AdSlotType';
import type { CountdownType, } from '../../flowTypes/CountdownType';
import type { HtmlElementType, } from '../../flowTypes/HtmlElementType';
import type { Interactive, } from '../../flowTypes/Interactive';
import type { IframeType, } from '../../flowTypes/IframeType';

type Props = {
  slotA: ListDataType[],
  slotB: AdSlotType[],
  slotC: ListDataType[],
  mainBlockComponents: Array<?CountdownType | ?HtmlElementType | ?IframeType | ?Interactive>,
};

const padding = [ { until: 's', value: '0 2rem', }, { from: 's', value: '4rem 4rem 4rem 4rem', }, ];

export default function MainBlock({ slotA, slotB, slotC, mainBlockComponents, }: Props): Node {
  const getComponent = useGetComponent();
  const { css, theme, } = useFela();

  // $FlowFixMe
  const countdownObj: ?CountdownType = mainBlockComponents.find(
    element => element && element.inputTemplate === 'com.tm.Countdown'
  );
  const slotAView = slotA && slotA.length > 0 && slotA[0].view;
  const isWideMain = [ 'Conrad', ].includes(slotAView);
  const isRoberto = slotAView === 'Roberto';

  const colTemplate = [ { from: 'l', until: isWideMain ? undefined : 'xl', value: '2fr 1fr', }, ];
  if (!isWideMain) colTemplate.push({ from: 'xl', value: '5fr 3fr 4fr', });

  // $FlowFixMe
  const iframes: ?IframeType[] = isRoberto ? mainBlockComponents.filter(isIframe) : null;

  // TODO: remove/amend htmlElement code after 2021 (first) elections
  const htmlElements = mainBlockComponents.filter(isAnHtmlElement)
    .map(htmlElement => ({
      htmlElement,
      // $FlowFixMe
      HtmlElement: getComponent(htmlElement.inputTemplate),
    }));
  const hasHtmlElements = htmlElements.length > 0;

  return isRoberto ? (
    <ListView
      innerBackgroundColor="white"
      padding={padding}
      marginTop={0}
      attrs={{
        'data-test': 'mainBlockTM',
        'data-main': 'mainBlock',
      }}
    >
      <List
        {...slotA}
        viewProps={{
          countdownObj,
          iframes,
        }}
      />
      {hasHtmlElements ? (
        <div
          className={css({
            extend: [
              theme.mq({ from: 'l', }, { gridColumnEnd: 'span 2', }),
            ],
          })}
        >
          {htmlElements.map(({ htmlElement, HtmlElement, }) => (
            <HtmlElement
              // $FlowFixMe
              key={htmlElement.contentId}
              {...htmlElement}
            />
          ))}
        </div>
      ) : null}
    </ListView>
  ) : (
    <ListView
      colTemplate={colTemplate}
      colGap={isWideMain ? '0' : [ { until: 'l', value: '0', }, { from: 'l', value: '4rem', }, ]}
      rowGap={[ { until: 's', value: '2rem', }, { from: 's', value: '4rem', }, ]}
      gridGap={null}
      innerBackgroundColor={[ { until: 's', value: 'transparent', }, { from: 's', value: 'white', }, ]}
      padding={padding}
      marginTop={0}
      attrs={{
        'data-test': 'mainBlockTM',
        'data-main': 'mainBlock',
      }}
    >
      {slotA && slotA.length > 0 && (
      <List
        {...slotA[0]}
        viewProps={{
          countdownObj,
        }}
      />
      )}
      {slotB && slotB.length > 0 && (
      <AdSlot
        {...slotB[0]}
        wrapperMiscStyles={{
          order: [ { from: 'l', value: '3', }, ],
          alignSelf: [ { from: 'xl', value: isWideMain ? 'auto' : 'center', }, ],
          display: [ { until: 's', value: 'none', }, ],
        }}
      />
      )}

      {slotC && slotC.length > 0 && <List {...slotC[0]} viewProps={{ isWideMain, }} />}
    </ListView>
  );
}

function isAnHtmlElement(candidate) {
  return candidate
    ? candidate.inputTemplate === 'com.tm.HtmlElement' || candidate.inputTemplate === 'com.tm.ArticleInteractiveHtmlElement'
    : false;
}
