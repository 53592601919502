// @flow
import * as React from 'react';
import { LEONARDOTM_LIST_QUERY, } from '@haaretz/graphql';
import LeonardoView from './LeonardoTmView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

type Props = {
  updateListDuplication: Function,
  listData: ListDataType,
  isPersonal: boolean,
};
export default function Leonardo(props: Props): React.Node {
  return (
    <ListWrapper {...props} query={LEONARDOTM_LIST_QUERY} view="Leonardo">
      {dataProps => <LeonardoView {...dataProps} />}
    </ListWrapper>
  );
}
