// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import type { Node, } from 'react';
import type { ClickTrackerBannerType, } from '../../../../flowTypes/ClickTrackerBannerType';
import type { ClickTrackerBannerWrapperType, } from '../../../../flowTypes/ClickTrackerBannerWrapperType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';

import ClickTracker from '../../../ClickTracker/ClickTrackerWrapper';
import Debug from '../../../Debug/Debug';
import HtzLink from '../../../HtzLink/HtzLink';
import Image from '../../../Image/Image';
import LayoutContainer from '../../../PageLayout/LayoutContainer';
import ListView from '../../../ListView/NewListView';
import getImageAssets from '../../../../utils/getImageAssets';

type Props = {
  list: ListDataType,
};

function LeonardoTm({ list, }: Props): Node {
  const { css, theme, } = useFela();
  const items: ?Array<ClickTrackerBannerWrapperType> = Array.isArray(list?.banners?.clickTrackerBanners) && list.banners.clickTrackerBanners.length > 4
    ? list.banners.clickTrackerBanners.slice(0, 5)
    : null;

  return items ? (
    <LayoutContainer
      miscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
      bgc="transparent"
      attrs={{
        'data-test': 'leonardoTm',
      }}
    >
      <ListView
        colTemplate={[
          {
            until: 'l',
            value: `repeat(${items.length > 4 ? items.length - 1 : items.length}, 1fr)`,
          },
          { from: 'l', value: `repeat(${items.length}, 1fr)`, },
        ]}
      >
        {items.map((item, i) => (
          <ClickTracker
            key={item.contentId}
            {...item}
            render={(banner: ClickTrackerBannerType) => {
              const { clicktrackerimage, link, } = banner;
              return (
                <HtzLink
                  href={link}
                  className={css({
                    ...(i > 3 ? theme.mq({ until: 'l', }, { display: 'none', }) : {}),
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '112px',
                  })}
                >
                  <Image
                    hasWrapper={false}
                    miscStyles={{ maxWidth: '227px', height: 'auto', }}
                    image={clicktrackerimage}
                    imgOptions={getImageAssets({
                      bps: theme.bps,
                      aspect: 'landscape',
                      sizes: [ { from: 'xl', size: '227px', }, { size: '227px)', }, ],
                      widths: [ 103, 125, 250, ],
                    })}
                  />
                </HtzLink>
              );
            }}
          />
        ))}
      </ListView>
    </LayoutContainer>
  ) : (
    <Debug>There is not enough items to render this list view</Debug>
  );
}

export default LeonardoTm;
