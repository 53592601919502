/* global window */
// @flow
import React, { useEffect, } from 'react';
import { useFela, } from 'react-fela';

import type { Node, } from 'react';
import { useLazyQuery, } from 'react-apollo';
import { EXIT_VALLEY_TEASERS, } from '@haaretz/graphql';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import Image from '../../../Image/Image';
import Button from '../../../Button/Button';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserSubtitle from '../../../TeaserSubtitle/TeaserSubtitle';
import TeaserContent from '../../../TeaserContent/NewTeaserContent';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import HtzLink from '../../../HtzLink/HtzLink';
import InitPixel from '../../../Scripts/InitPixel';

type Props = {
  list: ListDataType,
};

function Nibbler({ list, }: Props): Node {
  const { theme, css, } = useFela();

  const item = (list.items && list.items[0]) || {};

  const image = item?.mobileImage || item?.image;

  return (
    <ListView
      areasTemplate={[
        {
          until: 's',
          value: `
          "he"
          "t"
          "ev1"
          "ev2"
          "ev3"
          `,
        },
        {
          from: 's',
          until: 'l',
          value: `
          "he   he   he   he   he   he"
          "t    t    t    t    t    t"
          "ev1  ev1  ev2  ev2  ev3  ev3"
          "ev1  ev1  ev2  ev2  ev3  ev3"
      `,
        },
        {
          from: 'l',
          value: `
      "he he t t t t ev1 ev1 ev2 ev2 ev3 ev3"
      `,
        },
      ]}
      colTemplate={[
        { until: 's', value: '1fr', },
        { from: 's', until: 'l', value: '1fr 1fr 1fr 1fr 1fr 1fr', },
        { from: 'l', value: 'repeat(12,1fr)', },
      ]}
      miscStyles={{
        fontFamily: theme.fontStacks.commercial,
        border: [
          {
            until: 's',
            value: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
          },
        ],
        padding: [ { from: 's', value: '3rem 4rem', }, ],
      }}
      gridGap={[
        { until: 's', value: '0rem', },
        { from: 's', value: '4rem', },
      ]}
      sectionMiscStyles={{
        paddingInlineEnd: [ { until: 's', value: '2rem', }, ],
        paddingInlineStart: [ { until: 's', value: '2rem', }, ],
      }}
      attrs={{
        'data-test': 'nibbler',
      }}
    >
      {/* Header */}
      <ListViewHeader
        title={list.title}
        isCommercial
        additionalTitleMiscStyles={{
          color: theme.color('commercial'),
          ...theme.mq(
            { until: 's', },
            {
              ...theme.type(-2),
            }
          ),
          ...theme.mq(
            { from: 'l', until: 'xl', },
            { justifySelf: 'flex-start', marginTop: '1rem', }
          ),
          ...theme.mq(
            { from: 'xl', },
            {
              justifySelf: 'flex-start',
              marginTop: '1rem',
              ...theme.type(-2),
            }
          ),
        }}
        titleMiscStyles={{
          fontWeight: 500,
          display: 'block',
          ...theme.mq({ from: 's', until: 'l', }, { flexGrow: 1, }),
        }}
        miscStyles={{ fontFamily: theme.fontStacks.commercial, }}
        backgroundColor={[ { until: 's', value: [ 'commercial', 'bg', ], }, ]}
      />
      <InitPixel host="commercial" />
      <ExitValleyTeasers />
      <Teaser
        {...item}
        areasTemplate={[
          {
            until: 's',
            value: `
            ". . . . ."
            ". media content . ."
            ". media content  footer ."
            `,
          },
          {
            from: 's',
            until: 'l',
            value: `
            "media     .       .   . ."
            "media content content . ."
            "media footer footer footer ."
            `,
          },
          {
            from: 'l',
            value: `
          "media media     .       .   . ."
          "media media content content . ."
          "media media footer footer footer ."
          `,
          },
        ]}
        backgroundColor={[ 'neutral', '-7', ]}
        colTemplate={[
          { until: 's', value: '0 1fr 4fr auto 0', },
          { from: 's', until: 'l', value: '1fr 1fr 2fr 0 0', },
          { from: 'l', until: 'xl', value: '10fr 23fr 20fr 10fr 0 0', },
          { from: 'xl', value: '1fr 2fr 2fr 1fr 0 0', },
        ]}
        rowTemplate={[ { from: 's', value: '0 1fr auto', }, ]}
        gridGap="1rem"
        gridArea="t"
        miscStyles={{
          fontFamily: theme.fontStacks.commercial,
          borderWidth: '5px',
          border: [
            {
              from: 's',
              value: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
            },
          ],
          borderTop: [
            {
              until: 's',
              value: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
            },
          ],
          borderBottom: [
            {
              until: 's',
              value: [ '1px', 0, 'solid', theme.color('neutral', '-5'), ],
            },
          ],
          marginInlineEnd: [ { until: 's', value: '1rem', }, ],
          marginInlineStart: [ { until: 's', value: '1rem', }, ],
          paddingTop: [ { until: 's', value: '1rem', }, ],
          paddingBottom: [ { until: 's', value: '2rem', }, ],
          maxWidth: [ { until: 's', value: '100%', }, ],
          width: [ { until: 's', value: 'auto', }, ],
        }}
      >
        <TeaserMedia
          data={item}
          miscStyles={{
            width: [ { until: 's', value: '66px', }, ],
          }}
        >
          <Image
            image={image}
            imgOptions={{
              transforms: {
                width: '181',
                aspect: 'regular',
                quality: 'auto',
              },
            }}
            miscStyles={{
              display: [ { until: 's', value: 'none', }, ],
            }}
          />
          <Image
            image={image}
            imgOptions={{
              transforms: {
                width: '66',
                aspect: 'square',
                quality: 'auto',
              },
            }}
            miscStyles={{
              display: [ { from: 's', value: 'none', }, ],
            }}
          />
        </TeaserMedia>
        <TeaserContent tagName="header">
          <TeaserHeader
            {...item}
            title={item.title}
            typeScale={[
              { until: 'm', value: -0.5, },
              { from: 'm', until: 'l', value: 1, },
              { from: 'l', until: 'xl', value: -0.75, },
              { from: 'xl', value: -0.25, },
            ]}
            miscStyles={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              '-webkit-line-clamp': item.subtitle ? 2 : 3,
              '-webkit-box-orient': 'vertical',
            }}
          />
          <TeaserSubtitle
            {...item}
            subtitle={item.subtitle}
            typeScale={[
              { from: 'm', until: 'l', value: -1, },
              { from: 'l', until: 'xl', value: -2, },
              { from: 'xl', value: -1.75, },
            ]}
            miscStyles={{
              marginTop: '1rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
              display: [ { until: 'm', value: 'none', }, { from: 'm', value: '-webkit-box', }, ],
            }}
          />
        </TeaserContent>

        <footer
          className={css({
            gridArea: 'footer',
            textAlign: 'end',
            color: theme.color('neutral', '-2'),
            extend: [
              theme.type(-3),
              theme.mq({ from: 's', }, { paddingBottom: '1rem', }),
            ],
          })}
        >
          <img
            alt="ExitValley"
            src="https://www.exitvalley.com/Images/TopLogoOLDmob.png"
            className={css({
              maxHeight: '4rem',
              extend: [
                theme.mq({ from: 'l', }, {
                  maxHeight: '3.5rem',
                }),
              ],
            })}
          />
        </footer>
      </Teaser>
    </ListView>
  );
}

function ExitValleyTeasers(): Node {
  const { css, theme, } = useFela();

  const borderColor = theme.color('neutral', '-5');

  const [ loadExitValleyTeasers, { data, loading, error, called, }, ] = useLazyQuery(EXIT_VALLEY_TEASERS);

  useEffect(() => {
    loadExitValleyTeasers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!called) return null;
  if (loading) return null;
  if (error) return null;
  if (!data) return null;

  const teasersData = data.exitValleyTeasers;

  if (!teasersData || !Array.isArray(teasersData)) {
    return null;
  }

  return (
    <React.Fragment>
      {teasersData.map((teaser, idx) => (
        <HtzLink
          href={teaser.GoToURL}
          key={teaser.PID}
          className={css({
            fontFamily: theme.fontStacks.commercial,
            gridArea: `ev${idx + 1}`,

          })}
        >
          <div className={css({
            backgroundColor: theme.color('neutral', '-10'),
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor,
            overflow: 'hidden',
            height: '100%',
            display: 'grid',
            gridGap: '1rem',
            gridTemplateAreas: `
                                      "Logo"
                                      "Text"
                                      "Link"
                  `,
            gridTemplateColumns: '1fr',
            gridTemplateRows: '6rem 1fr auto',
            padding: '1rem',
            extend: [
              theme.mq({ until: 's', }, {
                backgroundColor: theme.color('neutral', '-7'),
                gridTemplateAreas: `
                      "Logo Text"
                      "Logo Link"
                      `,
                gridTemplateColumns: '11rem 1fr',
                gridTemplateRows: '1fr  auto',
                borderTop: 'none',
                borderStart: 'none',
                borderEnd: 'none',
                marginInlineEnd: '1rem',
                marginInlineStart: '1rem',
                paddingTop: '2rem',
                paddingBottom: '2rem',
                columnGap: '1rem',
              }),
            ],
          })}
          >
            <div className={css({
              position: 'relative',
              width: '100%',
              gridArea: 'Logo',
              textAlign: 'center',
            })}
            >

              <img
                className={css({
                  maxWidth: '100%',
                  maxHeight: '7.5rem',
                  extend: [
                    theme.mq({ until: 's', }, {
                      maxWidth: '11rem',
                      maxHeight: '100%',
                    }),
                  ],
                })}
                src={teaser.MainLogo}
                alt="cover"
              />
            </div>
            <div
              className={css({
                ...theme.mq({ until: 's', }, { textAlign: 'start', }),
                gridArea: 'Text',
                textAlign: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2,
                '-webkit-box-orient': 'vertical',
                extend: [
                  theme.type(-1, { until: 'm', }),
                  theme.type(-2, { from: 'm', }),
                  theme.mq({ until: 's', }, {
                    textAlign: 'start',
                  }),
                ],
              })}
            >
              {teaser.SloganHE}
            </div>
            <div
              className={css({
                gridArea: 'Link',
                textAlign: 'center',
                extend: [
                  theme.mq({ until: 's', }, {
                    textAlign: 'start',
                  }),
                ],
              })}
            >
              <div className={css({
                color: theme.color('commercial'),
                fontWeight: 'bold',
                extend: [
                  theme.type(-2, { from: 'm', }),
                  theme.mq({ until: 's', }, {
                    color: 'inherit',
                  }),
                ],
              })}
              >
                התחילו להשקיע
                <span className={css({ extend: [ theme.mq({ until: 's', }, { display: 'none', }), ], })}>{' >>'}</span>
              </div>
            </div>
          </div>
        </HtzLink>
      ))}
      <div
        className={css({
          display: 'block',
          textAlign: 'center',
          position: 'relative',
          ':before': {
            position: 'absolute',
            width: '100%',
            height: '1px',
            backgroundColor: theme.color('neutral', '-5'),
            top: '50%',
            start: '0',
          },
          extend: [
            theme.mq({ from: 's', }, { ':before': { content: '""', }, }),
            theme.mq({ from: 's', until: 'l', }, { gridColumn: '1 / 7', }),
            theme.mq({ from: 'l', }, { gridColumn: '3 / 13', }),
          ],
        })}
      >
        <Button
          href="https://www.exitvalley.com/"
          target="_blank"
          fontSize={[ { from: 'l', value: -1, }, ]}
          miscStyles={{
            fontFamily: theme.fontStacks.commercial,
            position: 'relative',
            zIndex: '1',
            boxShadow: [ { from: 's', value: '0 0 0 2rem #ebebeb', }, ],
            marginTop: [ { until: 's', value: '2rem', }, ],
            marginBottom: [ { until: 's', value: '2rem', }, ],
          }}
          variant="commercial"
        >
          לעוד השקעות
        </Button>
      </div>
    </React.Fragment>
  );
}

export default Nibbler;
